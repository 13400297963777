<template>
  <div>
    <v-dialog v-model="dialog" width="500" persistent>
      <v-card>
        <v-card-title>
          <span>{{ type === "like" ? "Daftar Like" : "Daftar Pembaca" }}</span>
          <v-spacer></v-spacer>
          <v-icon @click="close">mdi-close</v-icon>
        </v-card-title>
        <v-divider></v-divider>
        <v-card-text v-if="actionList.length > 0" class="pb-0">
          <v-list>
            <v-list-item v-for="(item, index) in actionList" :key="index">
              <v-list-item-avatar class="secondary">
                <v-img v-if="item.avatar" :src="item.avatar"></v-img>
                <span class="white--text mx-auto">{{
                  item.name.charAt(0).toUpperCase()
                }}</span>
              </v-list-item-avatar>

              <v-list-item-content>
                <v-list-item-title v-html="item.name"></v-list-item-title>
              </v-list-item-content>
            </v-list-item>
          </v-list>
        </v-card-text>
        <v-card-text v-else class="pa-4 text-center">
          <span>Data tidak ditemukan</span>
        </v-card-text>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import { readListElearning, likeListElearning } from "@/api/elearning";

export default {
  props: {
    type: String,
    dialog: Boolean,
    close: Function,
    data: [String, Number],
  },
  data() {
    return {
      actionList: [],
    };
  },
  watch: {
    dialog(newVal) {
      this.actionList = [];
      if (newVal && this.type === "read") {
        readListElearning({ id: this.data }).then((res) => {
          if (res.data.code) {
            this.actionList = res.data.data;
          } else {
            this.$store.commit("SET_SNACKBAR", {
              value: true,
              text: res.data.message,
              color: "error",
            });
          }
        });
      } else if (newVal && this.type === "like") {
        likeListElearning({ id: this.data }).then((res) => {
          if (res.data.code) {
            this.actionList = res.data.data;
          } else {
            this.$store.commit("SET_SNACKBAR", {
              value: true,
              text: res.data.message,
              color: "error",
            });
          }
        });
      }
    },
  },
};
</script>
