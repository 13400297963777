<template>
  <div id="elearning-detail">
    <div class="d-flex justify-space-between mb-4">
      <div class="d-flex align-center">
        <router-link to="e-learning"
          ><v-icon color="black">mdi-arrow-left</v-icon></router-link
        >
        <span class="body-1 ml-2">E-Learning Detail</span>
      </div>

      <v-btn v-if="userAccess()" depressed outlined @click="toElearningForm"
        >Edit</v-btn
      >
    </div>

    <v-sheet class="pa-4 white rounded-tl-xl rounded-tr-xl">
      <div class="d-flex justify-space-between">
        <table>
          <tr>
            <td>Kelas</td>
            <td class="px-2">:</td>
            <td class="font-weight-bold">{{ header.class }}</td>
          </tr>
          <tr>
            <td>Mata Pelajaran</td>
            <td class="px-2">:</td>
            <td class="font-weight-bold">{{ header.subject }}</td>
          </tr>
        </table>
      </div>
      <v-divider class="my-2"></v-divider>
      <div class="primary--text align-center mt-2 d-flex justify-space-between">
        <div>
          <v-avatar
            v-if="header.createdBy.avatar"
            :src="header.createdBy.avatar"
            alt="Author Avatar"
            size="36"
          ></v-avatar>
          <v-icon v-else class="mr-2">mdi-account-edit</v-icon>
          {{ header.createdBy.name }}
          <span class="caption ml-2 black--text">
            {{ header.createdAt | dateFormat }}
          </span>
        </div>
        <v-btn icon color="info" @click="onCopy"
          ><v-icon>mdi-share-variant</v-icon></v-btn
        >
      </div>
    </v-sheet>

    <v-sheet class="py-4 white mt-1">
      <div class="mx-auto" style="width: 80%">
        <h2 class="font-weight-bold mb-5 text-center">
          {{ header.title }}
        </h2>

        <iframe
          v-if="elearning.youtube"
          :src="getUrl(elearning.youtube)"
          :style="
            $vuetify.breakpoint.xsOnly ? 'height: 200px' : 'min-height: 70vh'
          "
          style="width: 100%"
        />

        <iframe
          v-if="elearning.files"
          :src="elearning.files"
          :style="
            $vuetify.breakpoint.xsOnly ? 'height: 200px' : 'min-height: 70vh'
          "
          style="width: 100%"
        />

        <div class="text-left" v-html="elearning.description"></div>

        <div class="d-flex">
          <div class="mr-2">
            <v-icon :color="isLike ? 'info' : '#CDCDCD'" @click="likeHandler"
              >mdi-thumb-up</v-icon
            >
            <v-btn text icon @click="openActionList('like')">
              <span style="color: #4a4a4a">{{ elearning.likeCount }}</span>
            </v-btn>
          </div>

          <v-btn class="elearning-action" text @click="openActionList('read')">
            <v-icon color="#CDCDCD">mdi-eye</v-icon>
            <span class="ml-2" style="color: #4a4a4a">{{
              elearning.readCount
            }}</span>
          </v-btn>
        </div>

        <ElearningActionList
          :type="actionType"
          :dialog="actionListModal"
          :close="() => (actionListModal = false)"
          :data="$route.query.id"
        />

        <v-divider class="my-6"></v-divider>

        <!-- Elearning Comment -->
        <div class="mt-4">
          <div class="body-1 mb-4">Komentar</div>
          <div v-if="commentTotal > 0">
            <v-row
              class="grey rounded-lg"
              v-for="(item, index) in commentList"
              :key="index"
            >
              <v-col v-if="!$vuetify.breakpoint.xsOnly" cols="12" sm="2" md="1">
                <v-avatar
                  v-if="item.avatar"
                  :src="item.avatar"
                  alt="Author Avatar"
                  size="40"
                  class="pa-2"
                ></v-avatar>
                <v-avatar v-else color="secondary" class="pa-2" size="40">
                  <span class="white--text">{{
                    item.name.charAt(0).toUpperCase()
                  }}</span>
                </v-avatar>
              </v-col>
              <v-col cols="12" sm="10" md="11">
                <div
                  class="body-1 font-weight-bold d-flex justify-space-between"
                >
                  {{ item.name }}
                  <v-menu
                    v-if="checkSender(item.sender)"
                    v-model="item.menuDelete"
                    :close-on-content-click="false"
                  >
                    <template v-slot:activator="{ on, attrs }">
                      <v-icon
                        v-bind="attrs"
                        v-on="on"
                        @click="setDeleteComment(item)"
                        >mdi-delete</v-icon
                      >
                    </template>
                    <v-card>
                      <v-card-text class="body-2">
                        Hapus Komentar?
                      </v-card-text>
                      <v-card-actions>
                        <v-spacer></v-spacer>
                        <v-btn
                          text
                          class="text-capitalize"
                          @click="item.menuDelete = false"
                        >
                          Batal
                        </v-btn>
                        <v-btn
                          color="primary"
                          depressed
                          class="text-capitalize"
                          @click="deleteCommentHandler()"
                        >
                          Hapus Komentar
                        </v-btn>
                      </v-card-actions>
                    </v-card>
                  </v-menu>
                </div>

                <div class="caption">
                  {{ item.created_at | dateTimeFormat }}
                </div>
                <div
                  v-text="item.comment"
                  class="mt-1"
                  style="white-space: pre"
                ></div>
              </v-col>
              <v-col v-if="index !== commentList.length - 1">
                <v-divider></v-divider>
              </v-col>
            </v-row>
          </div>
          <div v-else style="font-size: 13px">Belum ada komentar</div>

          <v-progress-linear
            v-if="loadingComment"
            indeterminate
            color="primary"
            class="mt-2"
          ></v-progress-linear>
          <div class="d-flex align-center mt-6">
            <div v-if="!$vuetify.breakpoint.xsOnly" class="pr-2">
              <v-avatar
                v-if="user.user.avatar"
                :src="user.user.avatar"
                alt="Author Avatar"
                size="40"
              ></v-avatar>
              <v-avatar v-else color="secondary" size="40">
                <span class="white--text">{{
                  user.person.name.charAt(0).toUpperCase()
                }}</span>
              </v-avatar>
            </div>
            <v-textarea
              v-model="commentVal"
              placeholder="Tulis komentar..."
              outlined
              flat
              rounded-sm
              rows="1"
              auto-grow
              dense
              hide-details
              block
              class="mx-2"
            ></v-textarea>
            <v-menu
              :close-on-content-click="false"
              :nudge-width="200"
              top
              offset-y
            >
              <template v-slot:activator="{ on, attrs }">
                <v-btn v-bind="attrs" v-on="on" icon class="mr-2">
                  <v-icon>mdi-emoticon</v-icon>
                </v-btn>
              </template>
              <VEmojiPicker class="mr-2" @select="selectEmoji" />
            </v-menu>
            <v-btn color="info" small fab depressed @click="sendComment"
              ><v-icon small>mdi-send</v-icon></v-btn
            >
          </div>
        </div>
        <p></p>
      </div>
    </v-sheet>
  </div>
</template>

<script>
import moment from "moment/moment";

import {
  detailElearning,
  likeElearning,
  commentElearning,
  commentListElearning,
  commentDeleteElearning,
} from "@/api/elearning";
import { VEmojiPicker } from "v-emoji-picker";
import ElearningActionList from "./ElearningActionList";

export default {
  name: "ElearningDetail",
  components: {
    VEmojiPicker,
    ElearningActionList,
  },
  filters: {
    dateFormat: (value) => {
      if (!value) return "";
      return moment(value).format("DD MMM YYYY");
    },
    dateTimeFormat: (value) => {
      if (!value) return "";
      return moment(value).format("DD MMM YYYY, HH:mm");
    },
  },
  data() {
    return {
      header: {
        subject: "",
        class: "",
        title: "",
        createdBy: "",
        createdAt: null,
      },
      elearning: {
        description: null,
        files: null,
        youtube: null,
        readCount: 0,
        likeCount: 0,
      },
      commentVal: "",
      actionType: null,
      actionListModal: false,
      commentList: [],
      commentTotal: 0,
      isLike: false,
      deleteCommentData: null,
      loadingComment: false,
    };
  },
  computed: {
    user() {
      return this.$store.getters.g_user;
    },
    getColor() {
      const colors = [
        "#E3B105",
        "#07294D",
        "#5089C6",
        "#FF5252",
        "2196F3",
        "#4CAF50",
        "#FFA000",
      ];

      return colors[Math.floor(Math.random() * colors.length)];
    },
  },
  async created() {
    if (this.$route.query.id) {
      this.$store.commit("SET_GLOBAL_LOADING", true);
      await detailElearning({ id: this.$route.query.id })
        .then((res) => {
          if (res.data.code) {
            const data = res.data.data;

            this.header = {
              subject: data.subject,
              class: data.class_name,
              title: data.title,
              createdBy: data.created_by,
              createdAt: data.created_at,
            };
            this.elearning = {
              description: data.description,
              files: data.file,
              youtube: data.youtube,
              readCount: data.read_count,
              likeCount: data.like_count,
              isLike: data.is_like,
            };
          }
        })
        .catch(() => this.$store.commit("SET_GLOBAL_LOADING", false));

      await this.getCommentList();
      this.$store.commit("SET_GLOBAL_LOADING", false);
    }
  },
  sockets: {
    connect: function () {
      const _self = this;
      if (_self.$route.query.id) {
        this.$socket.emit("joinElearningComment", {
          id: _self.$route.query.id,
        });
      }
    },
    clientMessage(data) {
      this.commentList.push(data.data);
    },
  },
  methods: {
    setDeleteComment(item) {
      this.deleteCommentData = item;
      item.menuDelete = true;
    },
    checkSender(id) {
      const role = this.$store.getters.g_user.user.role;
      const currentUser = this.$store.getters.g_user.person.id;
      return id === currentUser || role === "ADMIN";
    },
    deleteCommentHandler() {
      this.$store.commit("SET_GLOBAL_LOADING", true);
      commentDeleteElearning({ id: this.deleteCommentData.id })
        .then((res) => {
          if (res.data.code) {
            this.getCommentList();
          } else {
            this.$store.commit("SET_SNACKBAR", {
              value: true,
              text: res.data.message,
              color: "error",
            });
          }
          this.$store.commit("SET_GLOBAL_LOADING", false);
        })
        .catch(() => this.$store.commit("SET_GLOBAL_LOADING", false));
    },
    async getCommentList() {
      await commentListElearning({ id: this.$route.query.id })
        .then((res) => {
          if (res.data.code) {
            const comment = [];
            res.data.data.map((r) => comment.push({ ...r, menuDelete: false }));
            this.commentList = comment;
            this.commentTotal = this.commentList.length;
          }
        })
        .catch(() => this.$store.commit("SET_GLOBAL_LOADING", false));
    },
    sendComment() {
      this.loadingComment = true;
      const data = {
        id: this.$route.query.id,
        comment: this.commentVal,
      };

      commentElearning(data)
        .then((res) => {
          if (res.data.code) {
            this.$socket.emit("elearningComment", res.data.data);
            this.commentVal = "";
            this.getCommentList();
          }
          this.loadingComment = false;
        })
        .catch(() => (this.loadingComment = false));
    },
    openActionList(type) {
      this.actionType = type;
      this.actionListModal = true;
    },
    likeHandler() {
      if (this.isLike) {
        this.isLike = false;
        this.elearning.likeCount -= 1;
      } else {
        this.isLike = true;
        this.elearning.likeCount += 1;
      }
      likeElearning({ id: this.$route.query.id })
        .then((res) => {
          if (!res.data.code) {
            this.isLike = !this.isLike;
            this.elearning.likeCount -= 1;
          }
        })
        .catch(() => {
          this.isLike = !this.isLike;
          this.elearning.likeCount -= 1;
        });
    },
    selectEmoji(emoji) {
      this.commentVal = this.commentVal + emoji.data;
    },
    toElearningForm() {
      this.$router.push(`e-learning-form?id=${this.$route.query.id}`);
    },
    onCopy() {
      const url = new URL(location.href);
      const self = this;
      this.$copyText(url).then(
        function () {
          self.$store.commit("SET_SNACKBAR", {
            value: true,
            text: "Link E-Learning telah disalin",
            color: "success",
          });
        },
        function () {
          self.$store.commit("SET_SNACKBAR", {
            value: true,
            text: "Can not copy",
            color: "error",
          });
        }
      );
    },
    userAccess() {
      const role = this.$store.getters.g_roles;
      if (role === "ADMIN") {
        return true;
      } else if (role === "TEACHER") {
        if (this.$store.getters.g_user.person.id === this.header.createdBy.id) {
          return true;
        }
        return false;
      } else {
        return false;
      }
    },
    getUrl(url) {
      if (url) {
        let video_id = url.split("v=")[1];
        if (video_id) {
          const ampersandPosition = video_id.indexOf("&");
          if (ampersandPosition != -1) {
            video_id = video_id.substring(0, ampersandPosition);
          }
          const splitUrl = url.split("watch?v=");
          const newUrl = splitUrl[0] + "embed/" + video_id;
          if (splitUrl[0] != "https://www.youtube.com/" || !splitUrl[1]) {
            this.$store.commit("SET_SNACKBAR", {
              msg: "Url youtube tidak valid",
              color: "error",
            });
            return null;
          } else {
            return newUrl;
          }
        } else {
          this.$store.commit("SET_SNACKBAR", {
            msg: "Url youtube tidak valid",
            color: "error",
          });
        }
      }
    },
  },
};
</script>

<style lang="scss" scoped>
#elearning-detail {
  .elearning-action {
    cursor: pointer;
    span {
      font-size: 12px;
    }
  }
}
</style>
